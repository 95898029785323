.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:shadow-md {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.group .absolute {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
